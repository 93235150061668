import React ,{useRef, useState}from 'react';
import './homepage.css'; // Assuming your styles are in this file

import vision from "../../image/vission1.png";
import mission from "../../image/mission.png"
import teamMember1 from "../../image/IMG_0765.png"; // Replace with team member image
import teamMember2 from "../../image/Rakesh.png"; // Replace with team member image
import emailjs from 'emailjs-com';
import Carousel from '../Carousel/Carousel';
import Landingsection from '../Landingsection/Landingsection';
import aboutt from '../../image/aboutt.png'
import ContactUs from '../ContactUs/ContactUs';
import GoogleTranslate from '../GoogleTranslate/GoogleTranslate';
import contact from '../../image/contactus1.jpg';
const Homepage = () => {

    const form = useRef();

      const sendEmail = (e) => {
        e.preventDefault();
        
        // EmailJS integration
        emailjs.sendForm('service_zdzy7uj', 'template_gxsbntx', form.current, 'rom8gSkgP8Q-KXpqA')
          .then(
            (result) => {
              console.log('SUCCESS!', result.text);
              alert('Message sent successfully!');
            },
            (error) => {
              console.error('FAILED...', error.text);
              alert('Message failed to send.');
            }
          );
      };
    
  return (
    <div>

  <Landingsection/>

      {/* Landing Page Section */}
      {/* <div className="landing-page">
        <div className="overlay">
          <div className="text-container">
            <h1>PAPER AND BOARD MACHINES</h1>
            <h1>linking the BIG</h1>
          </div>
        </div>
      </div> */}

      {/* <div class="feature-section">
    <div class="feature-item">
        <div class="feature-circle">200+ Clients</div>
     
    </div>
    <div class="feature-item">
        <div class="feature-circle">Quality Assurance</div>
       
    </div>
    <div class="feature-item">
        <div class="feature-circle">Ethical Trading Values</div>
       
    </div>
    <div class="feature-item">
        <div class="feature-circle">Timely Shipment</div>
       
    </div>
    <div class="feature-item">
        <div class="feature-circle">Affordable Price</div>
    </div>
</div> */}

{/* <GoogleTranslate/> */}

<section class="do_section layout_padding" style={{marginTop:"70px",}}>
  <div class="container">
    <div class="do_container">
      <div class="box arrow-start arrow_bg">
        <div class="img-box">
          <span>200+</span><span>Clients</span>
        </div>
      </div>
      <div class="box arrow-middle arrow_bg">
        <div class="img-box">
          <span>Quality</span><span>Assurance</span>
        </div>
      </div>
      <div class="box arrow-middle arrow_bg">
        <div class="img-box">
          <span>Ethical</span><span>Trading</span><span>Values</span>
        </div>
      </div>
      <div class="box arrow-end arrow_bg">
        <div class="img-box">
          <span>Timely</span><span>Shipment</span>
        </div>
      </div>
      <div class="box">
        <div class="img-box">
          <span>Affordable</span><span>Price</span>
        </div>
      </div>
    </div>
  </div>
</section>


<div style={{marginTop:"110px"}}>
<Carousel/>
</div>

<div className="divider" />

      {/* About Us Section */}
      <div>
        <div className="about-container" id='about'>
          {/* Left Side - Text */}
          <div className="about-text">
            <h2 style={{color:"black"}}>About Us</h2>
            <p style={{color:"black",textAlign: "justify"}}>
            For more than 25 years, we, P. Rakesh Industries have been serving the paper industry with all our dedication and focus. Our company has been receiving immense appreciation for making available the best range of Pressure Former, Cylinder Mould, Spiderless Cylinder Mould, R F Vat, Blade Coater, Bar Coater, Extractor Press, Size Press, Coating Disperser, Roll Cleaner, etc. These products are acknowledged by customers owing to their incomparable quality. Our quality creations are available at market leading prices that highly suit the budgets of our clients. We have been able to fulfil bulk orders in minimum time owing to our great infrastructural facilities. Along with the quality, we also focus on the other aspects of services like accurate packaging as well as timely shipment.
<br></br>
As our company has the support of greatly experienced and diligent workers, it has been witnessing constant growth. The biggest credit for our enormous success goes to our team members that includes manufacturers, quality controllers, packaging personnel, warehousing experts and sales & marketing executives. All of them give their best to complete each job assigned to them with perfection. Because of the efficiency of our members, we have been able to make promises and always keep them.
             
                </p>
          </div>

          {/* Right Side - Image */}
          <div className="about-image">
            <img src={aboutt} alt="About Us - Factory"   style={{marginTop:"70px"}}/>
          </div>
        </div>
      </div>
      <div className="divider" />
      {/* Mission Section */}
      <div className='desktopresp' >
        <div className="about-containerrr"  style={{marginTop:"90px",marginBottom:"90px"}} >
          <div className="about-image">
            <img src={mission} alt="Mission" />
          </div>
          <div className="about-text" style={{display:"flex", justifyContent:"center", flexDirection: "column"}}>
            <h2 style={{color:"black", marginBottom: "10px" ,marginTop:"-15px" }}>Mission</h2>
            <p style={{color:"black",textAlign: "justify" }}>As a family-owned company, our mission is clear: P.Rakesh Industries is personal and flexible. our dedicated team is available to solve the smallest quries of our clients. having worked for leading companies accross the country as well as abroad, we understand the challenges faced.</p>
          </div>
        </div>
      </div>

      {/* Mission Section */}
      <div className="mobileresponsive">
        <div className="about-container">
          
          <div className="about-text" style={{display:"flex", justifyContent:"center", flexDirection: "column"}}>
            <h2 style={{color:"black", marginBottom: "10px" }}>Mission</h2>
            <p style={{color:"black",textAlign: "justify" }}>As a family-owned company, our mission is clear: P.Rakesh Industries is personal and flexible. our dedicated team is available to solve the smallest quries of our clients. having worked for leading companies accross the country as well as abroad, we understand the challenges faced.</p>
          </div>
          <div className="about-image">
            <img src={mission} alt="Mission" />
          </div>
        </div>
      </div>
      <div className="divider" />
      {/* Vision Section */}
      <div >
        <div className="about-container">
        <div className="about-text" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    <h2 style={{ color: "black", marginBottom: "10px",marginTop:"-15px" }}>Vision</h2>
    <p style={{ color: "black" ,textAlign: "justify"}}>Our vision is to widen the company's network and cover more major markets across the globe. We are giving our best to expand the clientele of our enterprise by attracting more customers. Soon, we will be transforming all our dreams into reality with the support of our passionate team members.</p>
</div>

          <div className="about-image">
            <img src={vision} alt="Vision" />
          </div>
        </div>
      </div>

      <div className="divider" />
{/* why to choose US */}
<div className="why-choose-section">
      <h2>Why Choose P. Rakesh Industries?</h2>
      <div className="features">
        <div className="feature">
          <h3>Expertise</h3>
          <p>
            Our team comprises seasoned professionals with extensive experience in paper mill machinery,
            ensuring that we deliver tailored solutions to meet your operational requirements.
          </p>
        </div>
        <div className="feature">
          <h3>Quality Assurance</h3>
          <p>
            We supply only genuine, high-performance parts, and our services adhere to strict quality
            control standards to maximize machinery uptime.
          </p>
        </div>
        <div className="feature">
          <h3>Rapid Response</h3>
          <p>
            Our streamlined logistics and service processes ensure swift delivery of parts and services,
            minimizing production disruptions.
          </p>
        </div>
      </div>
    </div>

    <div className="divider" />

{/* team members */}
<section >
      <h2 className="founder-heading">Founders</h2>
      <div className="founder-container">
        {/* Founder 1 */}
        <div className="founder-card">
          <img
             src={teamMember1}
            alt="Founder 1"
            className="founder-image"
          />
             <h3> Paresh Bharatia</h3>
             <p>  Co-Founder</p>
        </div>
        {/* Founder 2 */}
        <div className="founder-card">
          <img
            src={teamMember2}
            alt="Founder 2"
            className="founder-image"
          />
        <h3>Rakesh Bharatia</h3>
        <p>  Co-Founder</p>
        </div>
      </div>
    </section>

    <div className="divider" />
<div style={{marginTop:"70px"}}>


{/* <ContactUs/> */}
<div className="mobileresponsive">
<div className="about-container">

        <div className="about-text">
  
        <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSex_GdhK0PdKUPPMicNSgqS0SXrMUmtcv9qIs6z53ZEKvsOUA/viewform?embedded=true"
       width="100%"
       height="350px"
      >
        Loading…
      </iframe>
  
  </div>
  <div className="about-image">
            <img src={contact} alt="Vision" />
          </div>
          
        </div>
        </div>



  <div  className='desktopresp'>
<div className="about-containerrr">
<div className="about-image">
            <img src={contact} alt="Vision" />
          </div>
        <div className="about-text">
  
        <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSex_GdhK0PdKUPPMicNSgqS0SXrMUmtcv9qIs6z53ZEKvsOUA/viewform?embedded=true"
       width="100%"
       height="350px"
      >
        Loading…
      </iframe>
  
  </div>

          
        </div>
        </div>



</div>  

    </div>
  );
};

export default Homepage;
