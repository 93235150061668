import React from 'react';
import './Former.css';
import imgs from '../../image/imgs.png';
import imgss from '../../image/1234.png'
const Former = () => {
  return (
    <div className="spares-services-container">
        <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>1524 PRESSURE FORMER</h2>
    <div>
        <div className="former-container" id='about'>
           {/* left Side - Image */}
           <div className="about-image">
            <img src={imgs} alt="About Us - Factory" className='formerimg' />
          </div>
          {/* right Side - Text */}
          <div className="about-text">
          <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left"}}  className='desktopresponsiveview'>1524 PRESSURE FORMER</h2>
        <p style={{color:"black"}}><b>COMPARISON OF 1524 AND 1250 DIAMETER PRESSURE FORMER</b>
        </p>
        <p style={{color:"black"}}>The 1524mm diameter cylinder mould has a larger circumference, resulting in a lower RPM compared to the 1250mm diameter at the same speed as the paper or board machine. The advantages of 1524 diameter are as follows:</p>
        <br></br> 





        <div >
  <img src={imgss} alt="Mission" style={{ width: "100%", height: "300px" }} />
  
  <p
    style={{
      backgroundColor: "#4d7544",
      borderRadius: "10px",
      color: "white",
      paddingLeft: "40px",
      paddingBottom: "20px",
      paddingRight: "20px",
      paddingTop: "140px",
      width: "100%",
      boxSizing: "border-box", // ensures padding does not affect the width
    marginTop:"-160px",
  textAlign:"left"
    }}
  >
    <ul style={{ margin: "0", padding: "0" ,textAlign:"left"}}>
      <li style={{ marginTop: "-30px",textAlign:"left" }}>
        Due to the cylinder mould, the forming zone also gets longer, which allows increased drainage, resulting in more machine speed.
      </li> <br></br>  
      <li style={{textAlign:"left"}}>Better formation even at higher machine speeds due to reduced mould RPM and stock pressure.</li>
      <br></br>   <li style={{textAlign:"left"}}>Better formation and brightness are achieved with less pick-up, thus saving material in the top layer, protective layer, and Bottom layer. Minimizing production costs.</li>
      <br></br>   <li>Longer wire and couch roll life due to reduced mould RPM.</li>
      <br></br>   <li>Higher Machine speed, hence increased productivity.</li>
      <br></br>   <li>It can be installed as a single unit or coupled with multiple units in a sequence of 1250mm-diameter formers.</li>
    </ul>
  </p>
</div>
          </div>

         
        </div>
      </div>
  </div>
  );
};

export default Former;
