// src/Component/former/Former.js
import React from 'react';
import './blade.css'; // Import the CSS for styling

import mission from '../../image/blader.png'
import blade12 from '../../image/blade12.png'
import blade11 from '../../image/blade11.png'
import blade13 from '../../image/blade13.png'
const Blade= () => {
  return (
    <div className="spares-services-container">
    <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>BLADE / BAR COATER</h2>
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}

         
       <div className="about-imagee">
            <div>
         

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
   <img src={blade12} alt="Mission" />
 </div>

 <div className="image-container">
  <div className="image-item">
    <img src={blade11} alt="Image 1" className="responsive-image" />
  </div>
  <div className="image-item">
    <img src={blade13} alt="Image 2" className="responsive-image" />
  </div>
</div>

          <div>
          <h2 style={{color:"#4d7544",marginTop:"80px",textAlign:"left",fontSize:"26px"}}>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left"}}>
          <li> User-friendly adjustment for metering either with a bent blade or a beveled rigid blade.
</li>
      <li>The highest coating solution can be applied with the maximum solid contents.</li>
      <li> The preload at the coating blade is operated by a mechanical device; it’s free from wear and tear (no more pneumatic hose). Load can be changed by a single variable.
      </li>
      <li>All settings on the control panel, such as blade preload and blade angle control, are motorized.</li>
      <li>All coating conditions are displayed on the HMI (Human Machine Interface) screen.</li>
      <li>Quick interchange into metering bar mode is possible.</li>
      <li>Excellent quality of coated paper or board.</li>
      <li>Robust construction</li>
      
          </ul>
          </div>
          </div>
        </div>

      {/* right Side - Text */}
      <div className="about-text">
<div className='blade'>
      <h2 style={{color:"#4d7544",textAlign:"left"}}  className='desktopresponsiveview'>BLADE / BAR COATER</h2>

    <div >


<p
style={{
  backgroundColor: "#4d7544",
  borderRadius: "10px",
  color: "white",
  paddingLeft: "20px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  width: "100%",
  boxSizing: "border-box", // ensures padding does not affect the width

textAlign:"left"
}}
>
<span class="dot"></span> The PRI Blade Coater has unique design and produce high smooth surface of paper/board. This coater can produce LWC papers, art papers, special quality papers, folding box board, bottle carrier board. It can also be used for pre-coating and top coating of duplex board.<br></br>
  <br></br>   <span class="dot"></span>  Coating color is applied at the nip of the applicator roll in the direction of web metering done by the blade. Rubber-coated backing roll support the web while metering; this will avoid influencing the final surface finish.
</p>
</div>
</div>
<img src={mission} alt="Coating Process" style={{ marginTop: '20px', maxWidth: '100%', borderRadius: '8px' }} />
     
      </div>
    
     
    </div>
  </div>
</div>
  );
};

export default Blade;
