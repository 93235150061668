import React, { useState } from 'react';
import './navbar.css';
import logo from "../../image/logo11.png";

const Navbar = ({ setActiveComponent }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
    setDropdownOpen(false); // Close dropdown when menu is toggled
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="navbar-right">
        {menuOpen ? null : (
          <button className="hamburger" onClick={toggleMenu}>
            &#9776;
          </button>
        )}
        <ul className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <li onClick={() => { toggleMenu(); setActiveComponent('Homepage'); }}>Home</li>
          <li onClick={toggleDropdown} className="dropdown">
            Our Products
            {dropdownOpen && (
              <ul className="dropdown-menu">
                <li onClick={() => { toggleMenu(); setActiveComponent('PressureFormer'); }}>Pressure Former</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Cylinder'); }}>Cylinder Mould</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Spiderless'); }}>Spiderless Cylinder Mould</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Former'); }}>1524 Pressure Former</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Vat'); }}>Extractor Press</li>
                  <li onClick={() => { toggleMenu(); setActiveComponent('Blade'); }}>Blade/Bar Coater</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Coater'); }}>Bar Coater V3 & V2</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Size'); }}>Size Press</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Coating'); }}>Coating Disperser & Coating Kitchen Equipment</li>
                <li onClick={() => { toggleMenu(); setActiveComponent('Roll'); }}>Roll Cleaner</li>
              </ul>
            )}
          </li>
          <li onClick={() => { toggleMenu(); setActiveComponent('SparesAndServices'); }}>Spares and Services</li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
