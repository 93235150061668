// src/Component/former/Former.js
import React from 'react';
import './spiderless.css'; // Import the CSS for styling
import spider12 from '../../image/spiderless12.png'
const Spiderless = () => {
  return (
    <div className="spares-services-container">
    <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>SPIDERLESS MOULD</h2>
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}
       <div className="about-image">
        <img src={spider12} alt="About Us - Factory" className='spidimg' />
      </div>
      {/* right Side - Text */}
      <div className="about-text">
      <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left"}}  className='desktopresponsiveview'>SPIDERLESS MOULD</h2>

    <div >
<p
style={{
  backgroundColor: "#4d7544",
  borderRadius: "10px",
  color: "white",
  paddingLeft: "20px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  width: "100%",
  boxSizing: "border-box", // ensures padding does not affect the width
textAlign:"left"
}}
>
<span class="dot"></span> The PRI spiderless cylinder mould enables a smoother rate of flow in the cylinder, improved cleaning, and more uniform board quality. Spiderless cylinders are designed for high-speed pressure formers, R.F. vats, or dry vats.
        <br></br> <br></br>
          <span class="dot"></span> PRI spiderless mould is spiral wound construction. It has been hand-welded throughout, with filler metal at every welding point.
This ensures a cylinder mould that is sturdy, strong, and reliable. This mould is made with precise roundness and straightness. High accuracy of +/- 0.1 mm roundness is  achieved.
 
         <br></br><br></br>
         <span class="dot"></span>  Cylinder mould are normally manufactured with S.S. 304 or S.S. 316 construction, depending on the requirements of the customer.  
Due to its unique cage-type design, it can also be used as a Dandi roll, combi roll, etc. in fourdrinier system. Customised diameters can be manufactured to suit various  applications.
</p>

<h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px",marginTop:"20px"}}>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left",marginBottom:"60px"}}>
         <li>Excellent drainage</li>
            <li>It can be operated with a single cloth a single wire mesh.</li>
            <li>Better ply-bonding due to excellent drainage</li>
            <li>Better cleaning</li>
            <li>Lower operating  costs</li>
          </ul>
</div>
      </div>

     
    </div>
  </div>
</div>
  );
};

export default Spiderless;
;
