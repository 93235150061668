import React, { useState } from 'react';
import './App.css';
import Navbar from './Component/navBar/navbar';
import Footer from './Component/footer/footer';
import Homepage from './Component/homepage/homepage';
import PressureFormer from './Component/presserformer/presserformer';
import Former from './Component/Former/Former';
import Cylinder from './Component/cylinder/cylinder';
import Spiderless from './Component/spiderless/spiderless';
import Blade from './Component/blade/blade';
import Coater from './Component/coater/coater';
import Size from './Component/size/size';
import Coating from './Component/coating/coating';
import Roll from './Component/roll/roll';
import Vat from './Component/vat/vat';
import Sparesandservices from './Component/Sparesandservices/Sparesandservices';
import Details from './Component/details/details';


function App() {
  const [activeComponent, setActiveComponent] = useState('Homepage'); // Default component

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Homepage':
        return <Homepage />;
      case 'PressureFormer':
        return <PressureFormer />;
      case 'Former':
        return <Former />;
      case 'Cylinder':
        return <Cylinder />;
      case 'Spiderless':
        return <Spiderless />;
      case 'Blade':
        return <Blade />;
      case 'Coater':
        return <Coater />;
      case 'Size':
        return <Size />;
      case 'Coating':
        return <Coating />;
      case 'Roll':
        return <Roll />;
      case 'Vat':
        return <Vat />;

      case 'SparesAndServices': 
        return <Sparesandservices />
   
      default:
        return <Homepage />;
    }
  };

  return (
    <div className="App">
      <Navbar setActiveComponent={setActiveComponent} /> {/* Pass function as prop */}
      {renderComponent()} {/* Render component based on state */}
      <Footer setActiveComponent={setActiveComponent}/>
    </div>
  );
}

export default App;
