import React from 'react';
import './presserformer.css';
import imggg from '../../image/imggg.png';
import img1 from '../../image/img1.png'
import imgggg from '../../image/imgggg.png'
import flip from '../../image/flip.png'
const PressureFormer = () => {
  return (
    <div className="pressure-former">
      <h2 style={{color:"#4d7544",marginTop:"100px"}}  className='mobileresponsiveview'>PRESSURE FORMER</h2>
      <div className='desktopview'>
        
      <img src={img1} alt="Left Section" style={{width:"100%",marginTop:"50px"}}/>

      </div>
    
    <div className='mobileview'>     <div className="about-containner">
        
        <div className="about-imagee">
        
            <div>
         

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
   <img src={imgggg} alt="Mission" />
 </div>

 <div className="image-container">
  <div className="image-item">
    <img src={flip} alt="Image 1" className="responsive-image" />
  </div>
  <div className="image-item">
    <img src={imggg } alt="Image 2" className="responsive-image" />
  </div>
</div>

          <div>
          <h2 style={{color:"#4d7544",marginTop:"80px",textAlign:"left",fontSize:"26px"}}>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left"}}>
          <li>Improved sheet strength</li>
      <li>Excellent sheet formation at all speeds and substances</li>
      <li>MD/CD sheet gain ratio control</li>
      <li>Unaffected by normal freeness changes </li>
      <li>Higher speed capabilities </li>
      <li>Approaches fourdrinier type formation </li>
      <li>Basis weight and speed changes easily and quickly accomplished</li>
<li>Superior sheet quality and test versus standard cylinder forming techniques</li>
<li>Easy to maintain</li>

          </ul>
          </div>
          </div>
        </div>
        <div className="about-text">
        <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left"}}  className='desktopresponsiveview'>PRESSURE FORMER</h2>

          <p style={{
            backgroundColor:" #4d7544",borderRadius:"10px",
           color:"white",paddingBottom:"20px",paddingLeft:"40px",paddingRight:"20px",paddingTop:"60px"
          }}> 
           <ul style={{ margin: "0", padding: "0" }}>
      <li style={{ marginTop: "-30px" }}>
      The cylinder mould pressure former is one of the most efficient technologies for multilayer paper and board manufacturing. Each unit is a complete system and can be combined with identical units to make a multilayer board-forming system.  </li>
      <li>PRI Pressure Former is capable of producing boards with minimum cross-profile variation at higher speeds. PRI Pressure Former can also produce specialty paper as well as bond paper, cup stock, and equivalent papers. We provide our technical support for various products.</li>
      <li>The forming head is designed for a wide range of operations for producing various types of boards. To cope with changes in the GSM range, the gap between the cylinder mould and the forming lip can be adjusted by a precise micrometer provided at both ends. The PRI Pressure Former is also equipped with a floating throat adjuster operated by handwheel. The inlet centrifugal distributor is highly finished internally for smooth pulp entry into the former explosion chamber. The centrifugal distributor is equipped with dilution nozzles, which further minimizes cross-profile variation on board. As an option for customers, PRI also offers Taper manifold instead of centrifugal distributor.</li>
      <li>The PRI forming unit and cylinder mould are enclosed in a vat, which avoids water splashing at higher speeds while keeping the floor clean and dry for trouble-free operation. The vat is also equipped with an air extraction pipe on the side wall for air evacuation from the cylinder mould.
      </li>
     </ul>

          </p>
        </div>
        
      </div></div>


    </div>




  );
};

export default PressureFormer;
