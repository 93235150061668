// src/Component/former/Former.js
import React from 'react';
import './roll.css'; // Import the CSS for styling
import roll1 from '../../image/roll1.png';

const Roll = () => {
  return (

    <div className="spares-services-container">
   
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}
       <div className="about-image">
            {/* <div><img src={roll1} alt="Mission" /></div> */}
            <div>
            <h2 style={{color:"#4d7544",fontSize:"44px",textAlign:"left"}} className='viewfordesktop'>ROLL CLEANER</h2>
          <img src={roll1} alt="Mission" />
         
          </div>
          </div>
      {/* right Side - Text */}
      <div className="about-text">
    
      <h2 style={{color:"#4d7544",fontSize:"34px",textAlign:"left",marginTop:"-10px",fontSize:"26px"}}>Key Features:</h2>
           <ul style={{color:"black",textAlign:"left"}}>
           <li>The equipment is needful where the roll is difficult to access.
</li> 
<li>Uniform product quality thanks to roll cleaning equipment, resulting in no more complain of coated surface.
</li>
<li> Equipment can be run constantly or as per cleaning requirements.</li>
<li>No interruption during production, as cleaning is possible during the continuous process during continuous process, results in increased productivity.
</li>
<li>Easy and quick change of cleaning sponge thanks to Velcro fastening.
</li>

<li>A mist spray nozzle at the cleaning sponge is used for removing extremely sticky particles.
</li>
     
    </ul>
      </div>

     
    </div>
  </div>
</div>
  );
};

export default Roll;
