// src/Component/former/Former.js
import React from 'react';
import './vat.css'; // Import the CSS for styling
import extra1 from '../../image/extra1.png';
import extra2 from '../../image/extra2.png';
const Vat= () => {
  return (
    <div className="spares-services-container">
    <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>EXTRACTOR PRESS</h2>
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}
       <div className="about-image">
       <p style={{
            backgroundColor:" #4d7544",borderRadius:"10px",
           color:"white",padding:"20px", textAlign:"left"
          }}>  


        <b style={{fontSize:"20px"}}> P. Rakesh Industries Offers two different designs of Extractor press:
</b>
         <br></br><br></br>
         Top loading extractor roll  <br></br>
Bottom loading extractor roll

  <br></br>
<br></br> 
The extractor press is a low-energy-cost technique for extracting water from the board without any impact on the quality of the product. This economical technique uses the roll-nip method to achieve water removal. This can be installed with either one or two rolls. An arrangement of top loading or bottom loading can be used.<br></br>
<br></br>
<div class="image-container">
  <img src={extra2} alt="Mission" class="responsive-image" />
  <div class="overlay-text" style={{color:"white"}}>Bottom Loading</div>
</div>
          </p>
      </div>
      {/* right Side - Text */}
      <div className="about-text">
      <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left"}}  className='desktopresponsiveview'>EXTRACTOR PRESS</h2>
      <h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px"}}  className=''>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left"}}>
          <li>Excellent ply bonding, avoid blowing problems.
</li>
      <li>Sheet dryness can be improved  with low energy consumption.


</li>
      <li>Less moisture content before press, can apply more load at the first press.

</li>
<li>Farming can be supplied either with stainless steel cladding, complete stainless steel, or mild steel with epoxy paint on request.
</li>
<li>Improves productivity.</li>


          </ul>
          <div class="image-container">
  <img src={extra1} alt="Mission" class="responsive-image" />
  <div class="overlay-text" style={{color:"#4d7544"}}>Top Loading</div>
</div>
      </div>

     
    </div>
  </div>
</div>
  );
};

export default Vat;
;
