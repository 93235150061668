// src/Component/former/Former.js
import React from 'react';
import './coater.css'; // Import the CSS for styling

import bar1 from '../../image/bar1.png';
import bar2 from '../../image/bar2.png';
const Coater= () => {
  return (
      <div className="spares-services-container">

  <div>
      <div className="former-container" id='about'>
         {/* left Side - Image */}
         <div className="about-image">
         <div><h2 style={{color:"#4d7544"}}>BAR COATER V2</h2>
          <img src={bar2} alt="Mission" />
          <h2 style={{color:"#4d7544"}}>BAR COATER V3</h2>
          <img src={bar1} alt="Mission" />
         
          </div>
        </div>
        {/* right Side - Text */}
        <div className="about-text">
       
      <div >

<p
  style={{
    backgroundColor: "#4d7544",
    borderRadius: "10px",
    color: "white",
    paddingLeft: "20px",
    paddingBottom: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    width: "100%",
    boxSizing: "border-box", // ensures padding does not affect the width
textAlign:"left"
  }}
>
<span class="dot"></span> Bar coating is a competent technology for applying coating to the board. PRI bar coaters can be used to apply coating to both the top surface and the bottom surface with excellent coating coverage. Pigmented and non-pigmented types of coating are also possible on bar coater.<br></br>
<h3>  PRI offer coaters in two models</h3>

    &nbsp; &nbsp;&nbsp; &nbsp;  Barcoater-Horizontal Lead (Model V2)<br></br>
       &nbsp; &nbsp;&nbsp; &nbsp;   Barcoater- Vertical Lead (Model V3)<br></br><br></br>
 <span class="dot"></span> In a horizontal lead coater, the coating solution is applied to the applicator roll, and then it goes to the backing roll metering beam system for metering.<br></br>
 <br></br>  <span class="dot"></span> The vertical lead coater is facilitated by a nip coating arrangement where  the solution is applied at the applicator roll and metering is done immediately, which minimizes the dwell time.

          
</p>
<h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px",marginTop:"20px"}}>Key Features:</h2>
           <ul style={{color:"black",textAlign:"left"}}>
      <li>Rotating metering bar prevents sticking.
</li>
      <li>Service life is much longer than that of the blade.
</li>
      <li> Rapid connecting coupling between motor and metering bar allows quick changing of the metering bar.
</li>
      <li> Wear resistance rubber made bar bed with a water flow connection to keep the metering bar clean during operation.
</li>
      <li>Coat weight can be varied by regulating the air pressure.
</li>
      <li>The unit is easy to operate and has high functional reliability at constant coat quality.
</li>
     
    </ul>
</div>
        </div>

       
      </div>
    </div>
</div>
  );
};

export default Coater;
