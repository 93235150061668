import React, { useState, useEffect } from 'react';
import './Landingsection.css';

// Import images
import img1 from '../../image/Factory.png';
import img2 from '../../image/Slide former 2.png';
import img3 from "../../image/pic123.png"


const Landingsection = () => {
  // Array of images
  const images = [img1, img2,img3];

  // State for current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Effect to change the image every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <div className="landing-page" style={{ backgroundImage: `url(${images[currentIndex]})` }}>
      <div className="overlay">
        <div className="text-container">
          <h1>PAPER AND BOARD MACHINES</h1>
          <h1>linking the BIG</h1>
        </div>
      </div>
    </div>
  );
};

export default Landingsection;
