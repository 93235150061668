// src/Component/former/Former.js
import React from 'react';
import './cylinder.css'; // Import the CSS for styling
import cyl1 from '../../image/cyl1.png'
const Cylinder = () => {
  return (
    <div className="spares-services-container">
    <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>CYLINDER MOULD</h2>
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}
       <div className="about-image">
        <img src={cyl1} alt="About Us - Factory" className='cylimg' />
      </div>
      {/* right Side - Text */}
      <div className="about-text">
      <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left"}}  className='desktopresponsiveview'>CYLINDER MOULD</h2>

    <div >
<p
style={{
  backgroundColor: "#4d7544",
  borderRadius: "10px",
  color: "white",
  paddingLeft: "20px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  width: "100%",
  boxSizing: "border-box", // ensures padding does not affect the width
textAlign:"left"
}}
>
<ul><li>The cylinder mould is important equipment for producing multilayer paper on board. The PRI cylinder mould is designed for sturdy construction, corrosion resistance, and precise tolerance of machining. Due to its robust build quality, the PRI cylinder mould is suitable  for running in Pressure Formers, Restricted flow vats, or dry vats.</li> 
         <br></br>    <li>All necessary care is taken in designing  and manufacturing the cylinder mould which allows it to run at high speed with a sufficient nip load and proper dewatering. The outside diameter of the cylinder mould is machined to high accuracy for producing good- equality boards. PRI cylinder mould are normally manufactured with S.S. 304 material; in special cases, cylinder mould are offered in S.S. 316L material to avoid environmental effects. Selection of raw materials results in trouble-free operation over years.</li>
         <br></br>  <li>We can offer a higher -diameter cylinder mould for a higher-speed machine.</li> </ul>
</p>

<h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px",marginTop:"20px"}}>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left",marginBottom:"60px"}}>
            <li>High drawing capacity</li>
            <li>Near-perfect concentricity</li>
            <li>Maximum open area for dewatering</li>
            <li>Minimum deflection due to a design that emphasizes stiffness and rigidity</li>
            <li>Long wire life due to its highly finished outside diameter</li>
          </ul>
</div>
      </div>

     
    </div>
  </div>
</div>
  );
};

export default Cylinder;
