// src/Component/former/Former.js
import React from 'react';
import './coating.css'; // Import the CSS for styling
import coating12 from '../../image/coating12.png'
import coating1 from '../../image/coating1.png'
const Coating = () => {
  return (

    <div className="spares-services-container">
    <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>COATING DISPERSER AND KITCHEN EQUIPMENT</h2>
<div>
    <div className="former-container" id='about'>
       {/* left Side - Image */}
       <div className="about-image">
        <div>
        <img src={coating12} alt="About Us - Factory"  className='coatimg' />
        <h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px",marginTop:"20px"}}>Key Features:</h2>
           <ul style={{color:"black",textAlign:"left", paddingBottom:"5px"}}>
           <li>Double impeller ensures good dispersing </li>
      <li> High power with stronger processing ability
.</li>
      <li> Load cell is provided to accurately measure the prepared stock.</li>
     
    </ul>
    </div>
      </div>
      {/* right Side - Text */}
      <div className="about-text">
    
      <h2 style={{color:"#4d7544",textAlign:"left"}}  className='desktopresponsiveview'>COATING DISPERSER AND KITCHEN EQUIPMENT</h2>
      <img
  src={coating1}
  alt="Mission"
width={"280px"}

  style={{marginLeft:"-60px"}}
   className="responsive-imageeee"
/>
    <div >

<p
style={{
  backgroundColor: "#4d7544",
  borderRadius: "10px",
  color: "white",
  paddingLeft: "20px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  width: "100%",
  boxSizing: "border-box", // ensures padding does not affect the width
textAlign:"left"
}}
>

Coating disperser is suitable for product dispersing, mixing, blending, and other production processes. The disperser is capable of dispersing products with low fineness and blending them into the final product directly. The impeller of the disperser has a unique saw-toothed design that intensely stirs the solution at high speed. The impeller is designed according to the size of the tank to fully disperse the material in the tank efficiently.  <br></br> <br></br>


<div>
            <img
  src={coating1}
  alt="Mission"
  height="350px"
  // style={{ float: "left" ,paddingRight:"10px",marginLeft:"-163px"}}
   className="responsive-imagee"
/>
The impeller is constructed from S.S.316L, which ensures a good life. The impeller is dynamically balanced at high RPM, resulting in a longer life of the shaft and bearings. The tank of the coating disperser is fabricated with the utmost care, ensuring good roundness and excellent workmanship. The impeller of the disperser has a unique saw-toothed design, which intensely stirs the solution at high speed. The impeller is designed according to the size of the tank to fully disperse the material in tank efficiently. Impeller is constructed from S.S. 316L which ensures good life. The Impeller is dynamically balanced at high RPM resulting in longer life of shaft and bearings. The tank of coating disperser is fabricated with utmost care ensuring good roundness and excellent workmanship.
              <br></br> <br></br>A special flush bottom valve with an end disc opening is installed at the bottom of the tank for smooth drainage. On request, hydraulic lubricating system can be provided for quick lubrication even while running process.
          </div>            
</p>
</div>
      </div>

     
    </div>
  </div>
</div>
  );
};

export default Coating;
