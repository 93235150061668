// src/Component/former/Former.js
import React from 'react';
import './size.css'; // Import the CSS for styling


import size12 from '../../image/size12.png';
import size2 from '../../image/size2.png';
const Size = () => {
  return (
    <div className="about-sectionn">
{/* <img src={size} alt="Left Section" style={{width:"100%",}}/> */}


      <div className="about-containerrs">
        
        <div className="about-imageee">
        <div><h2 style={{color:"#4d7544", textAlign:"left",fontSize:"34px"}}>SIZE PRESS PNEUMATIC LOADING</h2>
          <img src={size12} alt="Mission" className='immg'/></div>
          {/* <img src={size12} alt="Mission"/> */}
        </div>
        <div className="about-textt">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
  <img src={size2} alt="Mission" height="200px" />
</div>
          <p style={{
            backgroundColor:" #4d7544",borderRadius:"10px",
           color:"white",padding:"20px",textAlign:"left"
          }}>  Size press is used for surface sizing on paper or board. It mainly improves the sheet strength; this is important for multi-layer kraft paper and duplex board. sizing papers also have the advantage of improving the printing properties of paper and board, as well as reducing ink consumption and special colour effect. We offer size press with hydraulic or pneumatic loading systems as per the customer’s requirements.
 </p>
        <h2 style={{color:"#4d7544",textAlign:"left",fontSize:"26px"}}>Key Features:</h2>
          <ul style={{color:"black",textAlign:"left"}}>
          <li> High Sizing efficiency </li>
      <li> High operational reliability</li>
      <li> Small space demand</li>
          </ul>
        </div>
        
      </div>
    </div>
  );
};

export default Size;
;
